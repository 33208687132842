var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-row",
        { staticClass: "text-center justify-center white--text mt-12" },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _vm._v("E-Mail Suppression File Summary Screen")
                  ]),
                  _vm._l(_vm.results, function(item, i) {
                    return _c(
                      "v-list-item-content",
                      { key: i },
                      [
                        _c("v-list-item-subtitle", [
                          _c("span", { staticClass: "font-weight-medium" }, [
                            _vm._v("Suppression Code: ")
                          ]),
                          _vm._v(_vm._s(_vm.$route.query.sc))
                        ]),
                        _c("v-list-item-subtitle", [
                          _c("span", { staticClass: "font-weight-medium" }, [
                            _vm._v("Total Email Addresses in File : ")
                          ]),
                          _vm._v(_vm._s(item.recordsImported))
                        ]),
                        _c("v-list-item-subtitle", [
                          _c("span", { staticClass: "font-weight-medium" }, [
                            _vm._v("Total Valid Email Addresses Imported: ")
                          ]),
                          _vm._v(_vm._s(item.recordsUploaded))
                        ])
                      ],
                      1
                    )
                  }),
                  _c("v-card-subtitle", [
                    _c("span", [_vm._v("Only unique records are imported.")]),
                    _c("br"),
                    _vm.results.recordsUploaded !== _vm.results.recordsImported
                      ? _c("span", [
                          _vm._v("Only unique records are imported.")
                        ])
                      : _vm._e()
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-5",
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push({ name: "EmailSuppression" })
                        }
                      }
                    },
                    [_vm._v("\n          Back to Main\n        ")]
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }