<template>
  <v-app>
    <v-row class="text-center justify-center white--text mt-12">
      <v-col cols="auto">
        <v-card>
          <v-card-title>E-Mail Suppression File Summary Screen</v-card-title>
          <v-list-item-content
            v-for="(item, i) in results"
            :key="i"
          >
            <v-list-item-subtitle><span class="font-weight-medium">Suppression Code: </span>{{ $route.query.sc }}</v-list-item-subtitle>
            <v-list-item-subtitle><span class="font-weight-medium">Total Email Addresses in File : </span>{{ item.recordsImported }}</v-list-item-subtitle>
            <v-list-item-subtitle><span class="font-weight-medium">Total Valid Email Addresses Imported: </span>{{ item.recordsUploaded }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-card-subtitle>
            <span>Only unique records are imported.</span><br>
            <span v-if="results.recordsUploaded !== results.recordsImported">Only unique records are imported.</span>
          </v-card-subtitle>
          <v-btn
            class="mb-5"
            color="primary"
            @click="$router.push({ name: 'EmailSuppression' })"
          >
            Back to Main
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>
<script>
import { nowwService } from '@/shared/services'

export default {
  name: 'EmailSuppressionSummarySummary',

  data () {
    return {
      resultsLoading: false,
      results: []
    }
  },

  created () {
    this.getEmailSuppressionSummary()
    this.scrollTop()
  },

  methods: {
    scrollTop () {
      setTimeout(() => {
        window.scrollTo({
          top: 0
        })
      }, 0)
    },
    async getEmailSuppressionSummary () {
      var _this = this
      _this.resultsLoading = true
      await nowwService.getEmailSuppressionSummary(_this.$route.query.sid)
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.results = resp.result
          }
        })
    }
  }
}
</script>
